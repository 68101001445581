// sw-registration.ts
export function registerSW(): void {
    if ("serviceWorker" in navigator) {
        window.addEventListener("load", () => {
            navigator.serviceWorker
                .register("/sw.js")
                .then((registration) => {
                    // Сразу проверяем наличие обновлений
                    registration.update();

                    // Можно периодически проверять обновления (например, каждую минуту)
                    setInterval(() => {
                        registration.update();
                    }, 60 * 1000);

                    registration.onupdatefound = () => {
                        const installingWorker = registration.installing;
                        if (installingWorker) {
                            installingWorker.onstatechange = () => {
                                if (installingWorker.state === "installed") {
                                    if (navigator.serviceWorker.controller) {
                                        // Новый контент обнаружен – перезагружаем страницу для загрузки свежего контента
                                        console.log(
                                            "New content is available. Reloading page...",
                                        );
                                        window.location.reload();
                                    } else {
                                        console.log(
                                            "Content is cached for offline use.",
                                        );
                                    }
                                }
                            };
                        }
                    };
                })
                .catch((error) => {
                    console.error(
                        "Error during service worker registration:",
                        error,
                    );
                });
        });
    }
}
